import { useNavigateWithReferrer } from '@dayinsure/shared';
import { Button, Card } from '@dayinsure/components';
import { PoliciesPaths } from '../../../../routes';
import { transformForCommaAndNoCommaValue } from '../../../../helpers';
import { VehicleDto } from '../../../../api/v1';

type PolicyInfoCarProps = {
  vehicle: VehicleDto;
  testId?: string;
  noMoreButton?: boolean;
};

export const PolicyInfoCar = ({ testId, vehicle, noMoreButton }: PolicyInfoCarProps) => {
  const navigate = useNavigateWithReferrer();
  const estimateValueComma = vehicle?.selfDeclaredVehicleValue?.amount
    ? transformForCommaAndNoCommaValue(String(vehicle.selfDeclaredVehicleValue?.amount))
    : null;
  const handleClick = () => {
    navigate(`car/${PoliciesPaths.CarInfo}`);
  };
  const vehicleDescription = `${vehicle?.make} ${vehicle?.model}`;

  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_car-details-card`}
      >
        <h2 className="mb-4 font-raleway text-lg">Car</h2>
        {vehicle?.registrationNumber && (
          <div
            data-testid={`${testId}_car-details-card_registration-number`}
            className="mt-4 mb-2"
          >
            <span
              className="inline-flex py-1 px-2 text-sm rounded bg-content-background"
              data-testid={`${testId}_car-details-card_registration-number_title`}
            >
              {vehicle?.registrationNumber}
            </span>
          </div>
        )}

        {vehicleDescription && (
          <div>
            <span
              className="text-base"
              data-testid={`${testId}_car-details-card_vehicle-description`}
            >
              {vehicleDescription}
            </span>
          </div>
        )}
        {estimateValueComma?.valueWithComa && (
          <Card.CardListItem
            label="Estimated value"
            icon="chart"
            testId={`${testId}_car-details-card_estimated-value`}
          >
            {`£${estimateValueComma?.valueWithComa}`}
          </Card.CardListItem>
        )}
        <Card.CardListItem
          label="Tracking device"
          icon="location"
          testId={`${testId}_car-details-card_tracking-device`}
        >
          {vehicle.trackingDevice?.code ? 'Yes' : 'No'}
        </Card.CardListItem>
        {vehicle?.alarmImmobiliser && (
          <Card.CardListItem
            label="Alarm / immobiliser"
            icon="alarm"
            testId={`${testId}_car-details-card_alarm-immobiliser`}
          >
            {vehicle.alarmImmobiliser.type?.description}
          </Card.CardListItem>
        )}
        <Card.CardListItem
          label="Usage"
          icon="cog"
          testId={`${testId}_car-details-card_usage-type`}
        >
          {vehicle.usage?.type?.description}
        </Card.CardListItem>
        <Card.CardListItem
          label="Parked during the day"
          icon="sun"
          testId={`${testId}_car-details-card_kept-at-day`}
        >
          {vehicle.location?.daytimeLocation?.description}
        </Card.CardListItem>
        <Card.CardListItem
          label="Parked at night"
          icon="moon"
          testId={`${testId}_car-details-card_kept-at-night`}
        >
          {vehicle.location?.overnightLocation?.description}
        </Card.CardListItem>
        {!noMoreButton && (
          <Button
            id={`${testId}_car_moreinfo-button`}
            testId={`${testId}_car_moreinfo-button`}
            text="More info"
            variant="bordered"
            onClick={() => handleClick()}
            classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
          />
        )}
      </Card>
    </section>
  );
};
