import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ExcessesCard,
  PaymentMobileFooter,
  QuoteJourneyLayout,
} from '../../../components';
import { QuoteDetailsReview } from './QuoteDetailsReview';
import { ReviewPageWrapper } from './ReviewPageWrapper';
import { Documents } from '../../../components/Documents';
import { CreateAccount } from './CreateAccount';
import { ConditionsCard } from '../../../components/Quote';
import { ContactDetails } from '../YourDetails/ContactDetails';
import { QuoteJourneyFormData } from '../../../types';
import { FeesAndCharges } from './FeesAndCharges';
import { isAnnualPaymentPlan } from '../../../helpers';
import { useQuoteQuery } from '../../../hooks';
import { MarketingPreferences } from '../YourDetails/MarketingPreferences';
import { AcceptStatement } from './AcceptStatement';

export const testId = 'review';

export const Review = () => {
  const {
    values: {
      proposer: {
        telephoneNumber: { mobile, landline },
      },
      reviewUpdate,
      usualPaymentFrequency,
    },
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data: quote } = useQuoteQuery(quoteId);
  const isPlannAnnual = isAnnualPaymentPlan(usualPaymentFrequency.code?.id);
  useEffect(() => {
    const contactDetailsNotAvailable = !mobile && !landline;
    setFieldValue('reviewUpdate', contactDetailsNotAvailable);
    return () => setFieldValue('reviewUpdate', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuoteJourneyLayout>
      <ReviewPageWrapper testId={testId}>
        <QuoteDetailsReview testId={testId} />
        <ConditionsCard testId={testId} />
        <Documents testId={testId} />
        <FeesAndCharges
          isPlannAnnual={isPlannAnnual}
          testId={testId}
          feesForDisplay={quote?.insurancePrice?.feesForDisplay}
        />
        <ExcessesCard testId={testId} />
        <CreateAccount testId={testId} />
        {reviewUpdate && (
          <>
            <ContactDetails testId={`${testId}_contact-details`} noEmailField />
            <MarketingPreferences testId={`${testId}_marketing-preferences`} />
          </>
        )}
        <AcceptStatement testId={testId} />
      </ReviewPageWrapper>
      <PaymentMobileFooter />
    </QuoteJourneyLayout>
  );
};
