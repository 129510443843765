/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketingConsentsRequestDto } from '../models/MarketingConsentsRequestDto';
import type { MarketingConsentsResponseDto } from '../models/MarketingConsentsResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MarketingConsentService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns MarketingConsentsResponseDto Success
   * @throws ApiError
   */
  public postApiV1MarketingConsentCreate(
    requestBody?: MarketingConsentsRequestDto,
  ): CancelablePromise<MarketingConsentsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/marketing-consent/create',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        422: `Client Error`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns MarketingConsentsResponseDto Success
   * @throws ApiError
   */
  public postApiV1MarketingConsentRetrieve(
    requestBody?: MarketingConsentsRequestDto,
  ): CancelablePromise<MarketingConsentsResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/marketing-consent/retrieve',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        422: `Client Error`,
      },
    });
  }

}