import { GlobalErrorContext } from '@dayinsure/shared';
import { useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { OpenAPI as OpenAPIv1 } from '../../../api/v1';
import { OpenAPI as OpenAPIv2, PersonResponse } from '../../../api/v2';
import { OpenAPI as OpenAPIv3 } from '../../../api/v3';
import { QuoteJourneyStatusContext } from '../../../contexts';
import {
  getCreateGuestAccountRequestPayload,
  getUpdatePersonRequestPayload,
} from '../../../helpers';
import { QuoteJourneyFormData } from '../../../types';
import { useV2PostPersonMutation } from '../../mutations';
import { usePerson } from '../../usePerson';
import { useV1PutPersonMutation } from '../../mutations/useV1PutPersonMutation';
import { useReferrer } from '../../useReferrer';

type SubmitPersonHandlerOptions = {
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
};

export const usePersonSubmit = () => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const { setIsError } = useContext(QuoteJourneyStatusContext);
  const createGuestAccountMutation = useV2PostPersonMutation();
  const updateGuestAccountMutation = useV1PutPersonMutation();
  const { personId: currentPersonId } = usePerson();
  const { signinSilent } = useAuth();
  const referrer = useReferrer();

  const getCreatePersonSuccessHandler =
    (onSuccessCallback?: VoidFunction) => async (personResponse: PersonResponse) => {
      if (
        personResponse.accessToken &&
        personResponse.id &&
        personResponse.refreshToken
      ) {
        // Create a partial auth user and push it to session.
        sessionStorage.setItem(
          `oidc.user:${process.env.REACT_APP_AUTH_AUTHORITY}:${process.env.REACT_APP_AUTH_CLIENT_ID}`,
          JSON.stringify({
            access_token: personResponse.accessToken,
            refresh_token: personResponse.refreshToken,
          })
        );

        signinSilent()
          .then(user => {
            OpenAPIv1.TOKEN = user?.access_token;
            OpenAPIv2.TOKEN = user?.access_token;
            OpenAPIv3.TOKEN = user?.access_token;

            onSuccessCallback?.();
          })
          .catch(() => {
            throw new Error('Failed to set up guest account');
          });
      } else {
        throw new Error('API did not return access token or id');
      }
    };

  const getErrorHandler = (onErrorCallback?: VoidFunction) => (error: unknown) => {
    setIsError(true);
    onErrorCallback?.();
    setGlobalError(error, {
      ctaId: 'quote-error_cta',
    });
  };

  return (
    values: QuoteJourneyFormData,
    { onSuccess, onError }: SubmitPersonHandlerOptions = {}
  ) => {
    if (currentPersonId) {
      updateGuestAccountMutation.mutate(
        {
          personId: currentPersonId,
          personData: getUpdatePersonRequestPayload(values),
        },
        { onSuccess, onError: getErrorHandler(onError) }
      );
    } else {
      createGuestAccountMutation.mutate(
        getCreateGuestAccountRequestPayload(values, referrer),
        {
          onSuccess: getCreatePersonSuccessHandler(onSuccess),
          onError: getErrorHandler(onError),
        }
      );
    }
  };
};
