import { Card, Icon, onEnter } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useNavigateWithReferrer } from '@dayinsure/shared';
import clsx from 'clsx';
import { useQuoteQuery } from '../../hooks';
import {
  getExcessesTotalValue,
  getExcessesValues,
} from '../../helpers/forms/getExcessesValue/getExcessesValue';
import { QuoteJourneyRoutes } from '../../routes';
import { ExcessCardDialog } from './ExcessCardDialog';
import { usePolicyQuery } from '../../hooks/queries/usePolicyQuery';
import {
  shouldIDisplayCompulsoryExcess,
  shouldIDisplayVoluntaryExcess,
} from '../../helpers/quote/cover';

type ExcessesCardProps = {
  testId?: string;
  noEdit?: boolean;
};

export const ExcessesCard = ({ testId, noEdit }: ExcessesCardProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigateWithReferrer();
  const { quoteId, id } = useParams<{ quoteId: string; id: string }>();
  const { data: quoteData } = useQuoteQuery(quoteId);
  const { data: policyData } = usePolicyQuery(id);

  const data = quoteData || policyData;
  const coverType = data?.cover?.type?.code || '';
  const displayVoluntaryExcess = shouldIDisplayVoluntaryExcess(coverType);
  const displayCompulsoryExcess = shouldIDisplayCompulsoryExcess(coverType);
  const excessesValues = getExcessesValues(
    // TODO in policy model there is no other than voluntary excesses remove comment when mode will update
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data?.excesses?.compulsoryAmounts,
    data?.excesses?.voluntaryAmounts
  );
  const excessesTotalValue = getExcessesTotalValue(
    // TODO in policy model there is no other than voluntary excesses remove comment when mode will update
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data?.excesses?.compulsoryAmounts,
    data?.excesses?.voluntaryAmounts
  );
  const onEdit = () => navigate(`/quote/${QuoteJourneyRoutes.Cover}`);
  const handleDialogVisibility = () => setDialogOpen((status: boolean) => !status);
  if (!displayCompulsoryExcess) {
    return null;
  }

  return (
    <Card
      paddingLevel="small"
      classNames={clsx('mt-6 lg:mt-8 bg-content-background-prominent')}
    >
      <div className="flex justify-between">
        <h2 className="text-lg">Excesses</h2>
        {!noEdit && (
          <button
            className="flex items-center text-sm font-semibold leading-[14px]"
            data-testid={`${testId}_edit-button`}
            onClick={onEdit}
            type="button"
          >
            <Icon name="edit" className="mr-2" size="12px" />
            Edit
          </button>
        )}
      </div>
      <div className="font-lato  text-sm">
        {displayVoluntaryExcess && (
          <div className="flex justify-between mt-6 leading-5">
            <span>Voluntary excess</span>
            <span className="font-bold">{excessesValues?.voluntary}</span>
          </div>
        )}
        {displayCompulsoryExcess && (
          <div className="flex justify-between leading-5">
            <span>Compulsory excess</span>
            <span className="font-bold">{excessesValues?.compulsory}</span>
          </div>
        )}
        <div className="flex justify-between leading-5">
          <span>Total excess</span>
          <span className="font-bold">{excessesTotalValue}</span>
        </div>
        <span
          role="button"
          tabIndex={0}
          className="flex gap-2 items-center my-2 mt-6 text-sm font-bold leading-3 text-cta-secondary-text"
          onClick={handleDialogVisibility}
          onKeyDown={onEnter(handleDialogVisibility)}
        >
          <Icon name="info" size="0.75rem" />
          View all excesses
        </span>
      </div>
      <ExcessCardDialog
        excessesValues={excessesValues}
        open={dialogOpen}
        close={handleDialogVisibility}
        testId={testId}
        dontDisplayVoluntary={!displayVoluntaryExcess}
        dontDisplayCompulsory={!displayCompulsoryExcess}
      />
    </Card>
  );
};
