import { useState, useMemo, useRef, useCallback } from 'react';
import {
  QuoteJourneyStatusContextWrapperProps,
  QuoteJourneyStatusContext,
} from './QuoteJourneyStatusContext';

const initialCopy = {
  title: 'Loading quote',
  subtitle: 'Fetching our best price for your car policy',
};

export const QuoteJourneyStatusContextWrapper = ({
  children,
}: QuoteJourneyStatusContextWrapperProps) => {
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [copy, setCopy] = useState(initialCopy);
  const quoteLoaderTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setIsLoading = useCallback((isQuoteLoading: boolean, minimumDuration = 0) => {
    if (isQuoteLoading) {
      setShowLoader(true);
      setDisplayLoader(true);
      document.getElementById('scroll-container')?.scroll({ top: 0, behavior: 'smooth' });
      quoteLoaderTimeout.current = setTimeout(() => {
        quoteLoaderTimeout.current = null;
        setShowLoader(false);
      }, minimumDuration);
    } else {
      if (!quoteLoaderTimeout.current) {
        setShowLoader(false);
      }
      setDisplayLoader(false);
    }
  }, []);

  const setIsError = useCallback((isQuoteError: boolean) => {
    if (isQuoteError) {
      setShowLoader(false);
      if (quoteLoaderTimeout.current) {
        clearTimeout(quoteLoaderTimeout.current);
      }
    }
  }, []);

  const resetCopy = () => setCopy(initialCopy);

  const contextValue = useMemo(
    () => ({
      showLoader: showLoader || displayLoader,
      isLoadingState,
      setIsLoadingState,
      setIsLoading,
      setIsError,
      copy,
      setCopy,
      resetCopy,
    }),
    [showLoader, displayLoader, isLoadingState, setIsLoading, setIsError, copy]
  );

  return (
    <QuoteJourneyStatusContext.Provider value={contextValue}>
      {children}
    </QuoteJourneyStatusContext.Provider>
  );
};

export const withQuoteJourneyStatusContextWrapper =
  <Props extends Record<string, unknown>>(Component: React.ComponentType<Props>) =>
  (props: Props) =>
    (
      <QuoteJourneyStatusContextWrapper>
        <Component {...props} />
      </QuoteJourneyStatusContextWrapper>
    );
