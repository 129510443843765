import { ContentContainer, Card, Icon } from '@dayinsure/components';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { BackButton, FormCheckbox, MtaChangeButtons } from '../../../../components';
import { InfoCard } from './InfoCard';
import { PaymentType } from '../../../Quote/YourQuote/PaymentType';
import {
  formatNegativePrice,
  getCurrentPaymentPlan, isMonthlyPaymentPlan,
  toFixedString,
} from '../../../../helpers';
import { MtaJourneyFormData } from '../../../../types/mtaJourneyForm';
import { usePaymentInfo } from '../../../../hooks';
import { PaymentCard } from '../../../../components/PaymentCard';
import { getNewCostBackLink } from './NewCost.utils';
import { GetMotorQuoteResponseDto } from '../../../../api/v1';

type InsurancePriceKey = 'netPremium' | 'total' | 'insurancePremiumTax';
const testId = 'mta-new-cost';
const increaseText =
  'Making these changes means the cost of your policy will <strong>increase</strong> by ';
const decreaseText =
  'Making these changes means the cost of your policy will <strong>decrease</strong> by ';
const equalText =
  'Making these changes means the cost of your policy will <strong>remain the same</strong>';

const getPrice = (
  data: GetMotorQuoteResponseDto | undefined,
  key: InsurancePriceKey,
  showSign = false
): string => {
  const amount = data?.insurancePrice?.[key]?.amount;
  const price = `£${toFixedString(amount)}`;
  if (amount === 0) {
    return '£0';
  }
  if (amount && amount > 0) {
    return showSign ? `+${price}` : price;
  }
  return `-${price.replace('-', '')}`;
};

export const NewCost = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardInfoMessage, setCardInfoMessage] = useState<string>('');
  const [isRefund, setIsRefund] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<MtaJourneyFormData>();
  const { isLoading, data } = usePaymentInfo();
  const { id } = useParams<{ id: string }>();
  const currentPlan = getCurrentPaymentPlan(
    values.usualPaymentFrequency,
    data?.paymentPlans
  );
  const isMonthlyPlan = isMonthlyPaymentPlan(currentPlan?.type?.code);
  const hasEndorsments202or208 =
    !!values?.carSecurity?.isEndorsement202 || !!values?.carSecurity?.isEndorsement208;
  const checkboxPath = 'newCostAgreement';
  const isCheckboxSelected = values[checkboxPath];
  useEffect(() => {
    if (data) {
      const costDifference = data.insurancePrice?.total;
      if (costDifference) {
        if (costDifference?.amount === 0) {
          setCardInfoMessage(equalText);
        } else if (costDifference.amount && costDifference.amount < 0) {
          setCardInfoMessage(
            `${decreaseText} <strong>£${toFixedString(
              Math.abs(costDifference?.amount)
            )}</strong>`
          );
          setFieldValue('newCost.isRefund', true);
          setIsRefund(true);
        } else {
          setCardInfoMessage(
            `${increaseText} <strong>£${toFixedString(costDifference?.amount)}</strong>`
          );
        }
      }
    }
  }, [data, data?.insurancePrice?.total, searchParams, setFieldValue, setSearchParams]);

  useEffect(() => {
    setFieldValue(checkboxPath, false);
  }, [setFieldValue]);

  if (!id) {
    return null;
  }
  const selectPlanFromApi = (plan: { id: string; name: string }) => {
    setFieldValue('usualPaymentFrequency.code', plan);
  };
  return (
    <div className="font-raleway">
      <BackButton
        customBackLink={getNewCostBackLink(hasEndorsments202or208, values.policyChange)}
      />
      <ContentContainer>
        <h1
          className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
          data-testid={`${testId}_form-title`}
        >
          Your new cost
        </h1>
        {cardInfoMessage && <InfoCard costMessage={cardInfoMessage} />}
        <Card paddingLevel="small" classNames="mt-6 lg:mt-8 text-sm">
          <h2 className="pb-4 text-lg">Cost for changes</h2>

          {data?.insurancePrice?.fees && data?.insurancePrice?.fees?.[0].total?.amount && (
            <div className="flex justify-between">
              <div>Amendment fee</div>
              <div className="font-bold">
                {formatNegativePrice(data?.insurancePrice?.fees?.[0].total?.amount)}
              </div>
            </div>
          )}

          {data?.insurancePrice?.insurancePremiumTax && (
            <div className="flex justify-between">
              <div>Insurance premium tax</div>
              <div className="font-bold">
                {getPrice(data, 'insurancePremiumTax', true)}
              </div>
            </div>
          )}

          <div className="flex justify-between">
            <div>Policy cost difference</div>
            <div className="font-bold">{getPrice(data, 'netPremium', true)}</div>
          </div>
          {currentPlan?.totalCost?.amount && (
            <div className="flex justify-between">
              <div>Total cost for changes</div>
              <div className="font-bold">
                {formatNegativePrice(currentPlan?.totalCost?.amount)}
              </div>
            </div>
          )}
        </Card>

        <Form>
          {data && data?.paymentPlans && (
            <>
              <PaymentType
                disabled={isLoading}
                paymentPlans={data.paymentPlans}
                insurencePrice={data.insurancePrice}
                totalPriceIncAddOns={data.totalPriceIncAddOns}
                testId={testId}
                title="Payment method"
                showTooltip={false}
                isMta
                isRefund={isRefund}
                onChangeOptional={selectPlanFromApi}
              />
            </>
          )}
          {isRefund && !isMonthlyPlan && (
            <Card
              classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6 mb-6 lg:mb-10"
              paddingLevel="small"
            >
              <Icon name="warning" size="2rem" className="text-popup-content-1" />
              <p>
                This amount will be refunded to the original card(s) you paid with,
                normally within 5 to 7 working days.
              </p>
            </Card>
          )}
          {values.usualPaymentFrequency.code?.id && data && (
            <PaymentCard
              addOns={data.addOns}
              insurancePrice={data.insurancePrice}
              paymentPlans={data.paymentPlans}
              cover={data.cover}
              totalPriceIncAddOns={data.totalPriceIncAddOns}
              excesses={data.excesses}
              disabled={isLoading}
              testId={testId}
              mta
              isRefund={isRefund}
              usualPaymentFrequency={values.usualPaymentFrequency}
            />
          )}

          <section>
            <Card
              classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
              paddingLevel="small"
              testId={`${testId}_warning-card`}
            >
              <Icon className="text-main-content-1" size="2rem" name="warning" />
              <div className="ml-6 text-main-content-1">
                The details you’ve provided must be accurate to the best of your knowledge
                and you agree to make us aware if they change.
              </div>
            </Card>
          </section>

          <Card
            classNames={clsx('flex items-center', 'mb-12', 'mt-8', 'lg:mt-12')}
            paddingLevel="none"
            testId={`${testId}_agreement-box`}
          >
            <FormCheckbox
              displayText="I agree and accept"
              name={checkboxPath}
              id={`${testId}_agreement-input`}
              testId={`${testId}_agreement-input`}
              noBorders
            />
          </Card>

          <MtaChangeButtons
            disabled={
              !values?.usualPaymentFrequency?.code || isLoading || !isCheckboxSelected
            }
          />
        </Form>
      </ContentContainer>
    </div>
  );
};
