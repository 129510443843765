import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { AddOnModelDto, CoverDto } from '../../../../api/v1';

type PolicyInfoCoversProps = {
  testId?: string;
  cover?: CoverDto;
  addons?: AddOnModelDto[] | null;
};

export const PolicyInfoCovers = ({ testId, cover, addons }: PolicyInfoCoversProps) => {
  const displayAddOns = addons?.length !== 0;
  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_cover`}
      >
        <h2 className="mb-4 font-raleway text-lg">Cover</h2>
        <div
          className={clsx({ 'mb-4': displayAddOns })}
          data-testid={`${testId}_cover_cover-type_list-item`}
        >
          <div className="flex items-center mb-2 text-sm text-main-content-2">
            <Icon name="dpolicies" />
            <span
              className="ml-2"
              data-testid={`${testId}_cover_cover-type_list-item_label`}
            >
              Cover type
            </span>
          </div>
          <div
            className={clsx('block mt-1', { 'mb-2': displayAddOns })}
            data-testid={`${testId}_cover_cover-type_list-item_content`}
          >
            {cover?.type?.description}
          </div>
        </div>

        {displayAddOns && (
          <div data-testid={`${testId}_cover_cover_addons_list-item`}>
            <div className="flex items-center mb-2 text-sm text-main-content-2">
              <Icon name="addons" />
              <span
                className="ml-2"
                data-testid={`${testId}_cover_cover_addons_list-item_label`}
              >
                Add-ons
              </span>
            </div>
            <div
              className="block mt-1 mb-2"
              data-testid={`${testId}_cover_cover_addons_list-item_content`}
            >
              {addons?.map((addon, index) => (
                <div key={`${testId}_addon-${index.toString()}`}>
                  • {addon.type?.description || addon.type?.code}
                </div>
              ))}
            </div>
          </div>
        )}
      </Card>
    </section>
  );
};
