import { Button } from '@dayinsure/components';
import { Form } from 'formik';
import { QuoteJourneyLayout, TopCards } from '../../../components';
import { OccupationFields } from '../../../components/OccupationFields';
import { DriverBasicInfo } from '../../../components/Quote';
import { ContactDetails } from './ContactDetails';
import { LiveInUK } from './LiveInUK';
import { MarketingPreferences } from './MarketingPreferences';
import { QuoteJourneyFormData } from '../../../types';

const testId = 'your-details';

export const YourDetails = () => {
  return (
    <QuoteJourneyLayout>
      <h1
        className="mb-8 text-xl text-center md:text-left"
        data-testid={`${testId}_title`}
      >
        Your details
      </h1>
      <TopCards
        testId={`${testId}_cards`}
        infoCardText="To get you our best quote, we need a few details about you, the policyholder."
      />
      <div className="mb-16 sm:mb-24">
        <Form>
          <DriverBasicInfo<QuoteJourneyFormData>
            formNameSpaceKey="drivers[0]"
            testId={`${testId}_proposer-info`}
          />
          <LiveInUK<QuoteJourneyFormData>
            formNameSpaceKey="drivers[0]"
            testId={`${testId}_live-in-UK`}
          />
          <OccupationFields<QuoteJourneyFormData>
            formNameSpaceKey="drivers[0]"
            testId={`${testId}_proposer-occupations`}
          />
          <ContactDetails testId={`${testId}_contact-details`} />
          <MarketingPreferences testId={`${testId}_marketing-preferences`} />
          <Button
            id={`${testId}_continue_button`}
            testId={`${testId}_continue_button`}
            submit
            fullWidth
            icon={{ name: 'arrow-right', position: 'right' }}
            text="Continue"
          />
        </Form>
      </div>
    </QuoteJourneyLayout>
  );
};
